import React, { useState } from 'react';

function SignupComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!username || !password || !email) {
            setMessage('Tutti i campi sono obbligatori');
            return;
        }

        setLoading(true);
        setMessage('');

        try {
            const response = await fetch('/api/signup_app', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password, email }),
            });
            const data = await response.json();

            if (response.ok) {
                setMessage('Registrazione completata!');
                setUsername('');
                setPassword('');
                setEmail('');
            } else {
                setMessage(data.error || 'Errore di registrazione');
            }
        } catch (err) {
            setMessage('Errore di connessione');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSignup} style={styles.form}>
            <h2 style={styles.header}>Registrazione</h2>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={styles.input}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
                required
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
                required
            />
            <button type="submit" style={styles.button} disabled={loading}>
                {loading ? 'Registrazione in corso...' : 'Registrati'}
            </button>
            {message && <p style={loading ? styles.loadingMessage : styles.message}>{message}</p>}
        </form>
    );
}

const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        margin: 'auto',
        padding: '2rem',
        backgroundColor: '#f7f9fc',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        marginBottom: '1rem',
        fontSize: '24px',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '1rem',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
    },
    button: {
        padding: '10px 15px',
        color: '#fff',
        backgroundColor: '#3498db',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    message: {
        color: '#2ecc71',
        marginTop: '1rem',
        fontSize: '14px',
    },
    loadingMessage: {
        color: '#e67e22',
        marginTop: '1rem',
        fontSize: '14px',
    }
};

export default SignupComponent;
