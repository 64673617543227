import React from 'react';
import { useState } from 'react';
import website from "../assets/website.JPG";
import { features1 } from "../constants";
import styles, { layout } from "../style";
import { footerLinks, socialMedia } from "../constants";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import videoSrc from "../assets/DigitalTwinSAIX.mp4";

const FeatureCard = ({ icon, title, index }) => (
  <div className={`flex flex-row p-6 rounded-[20px] min-w-[200px] ${index !== features1.length - 1 ? "mb-6" : "mb-0"} feature-card shadow-lg`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="icon" className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3 justify-center" style={{ maxWidth: '300px' }} >
      <h4 className="font-poppins font-semibold text-black text-[18px] mb-1">
        {title}
      </h4>
    </div>
  </div>
);

function Home() {
  return (
    <section className="pt-24 bg-gradient-to-b from-white to-gray-100">
      <div className="px-12 mx-auto max-w-full relative overflow-hidden">

        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-1/2 mx-auto text-left md:text-center md:pl-8">
            <h1 className="mb-8 text-3xl font-extrabold leading-none tracking-normal text-gray-900 md:text-5xl md:tracking-tight">
              <span>Optimize your transportations with our </span>
              <span className="block w-full py-2 text-transparent bg-clip-text leading-12 text-gradient lg:inline">
                Digital Services
              </span>
              <span> for fleet management</span>
            </h1>
            <p className="px-0 mb-8 text-base text-gray-600 md:text-lg lg:px-24">
              Improve your company and save money
            </p>
            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
              <Link
                to="/login"
                className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg text-white bg-green-500 rounded-full shadow-lg sm:w-auto sm:mb-0 hover:bg-green-600 transition duration-300"
              >
                Get Started
                <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </Link>
              <Link
                to="/services"
                className="inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-lg bg-gray-200 rounded-full shadow-lg sm:w-auto sm:mb-0 hover:bg-gray-300 transition duration-300"
              >
                Know more about us
                <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>

        <div className={`${layout.sectionImg} flex flex-wrap justify-center mt-12`}>
          {features1.map((feature, index) => (
            <div key={feature.id} className="card-margin">
              <FeatureCard {...feature} index={index} />
            </div>
          ))}
        </div>

        <div className="mt-12 flex flex-wrap justify-center">
          <div className="flex flex-col items-center mb-4 space-x-0 md:space-x-2 md:mb-8" style={{ maxWidth: '500px' }}>
            <h2 className="text-2xl font-bold mb-4">See more about our services</h2>
            <p>Follow our process to create a digital twin of your vehicle, from the 3d design to the implementation with your database of trucks. Our software will connect to the real vehicle in order to collect all the data from the transportation thanks to the mounted telematic unit.</p>
            <Link
              to="/services"
              className="inline-flex items-center justify-center px-3 py-2 mt-2 text-sm text-white bg-green-500 rounded-full shadow-sm sm:px-4 sm:py-2 hover:bg-green-600 transition duration-300"
            >
              See more
              <svg className="w-2 h-2 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            </Link>
          </div>

          <div className="aspect-w-16 aspect-h-9" style={{ maxWidth: '700px' }}>
            <video autoPlay muted loop className="rounded-lg shadow-lg">
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
