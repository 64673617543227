// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services-section {
  background-color: #f9fafb;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.service-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-bg {
  display: inline-block;
  padding: 1rem;
  background-color: #e3f2fd;
  border-radius: 50%;
  color: #1976d2;
}

.service-title {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #374151;
}

.service-description {
  margin-top: 1rem;
  color: #6b7280;
}

.learn-more-link {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: #1976d2;
  text-decoration: none;
  transition: color 0.3s;
}

.learn-more-link:hover {
  color: #0d47a1;
}

.learn-more-link svg {
  margin-left: 0.5rem;
}

.container-photo{
  max-width: 600px;
}

.center-container {
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/component/Services.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".services-section {\n  background-color: #f9fafb;\n}\n\n.container {\n  max-width: 1400px;\n  margin: 0 auto;\n  padding: 2rem;\n}\n\n.service-card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 1rem;\n  text-align: center;\n  background-color: #ffffff;\n  border-radius: 10px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.icon-bg {\n  display: inline-block;\n  padding: 1rem;\n  background-color: #e3f2fd;\n  border-radius: 50%;\n  color: #1976d2;\n}\n\n.service-title {\n  margin-top: 1rem;\n  font-size: 1.5rem;\n  font-weight: 600;\n  color: #374151;\n}\n\n.service-description {\n  margin-top: 1rem;\n  color: #6b7280;\n}\n\n.learn-more-link {\n  display: flex;\n  align-items: center;\n  margin-top: 1rem;\n  color: #1976d2;\n  text-decoration: none;\n  transition: color 0.3s;\n}\n\n.learn-more-link:hover {\n  color: #0d47a1;\n}\n\n.learn-more-link svg {\n  margin-left: 0.5rem;\n}\n\n.container-photo{\n  max-width: 600px;\n}\n\n.center-container {\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
