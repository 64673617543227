import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginComponent({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/api/login_app', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('token', data.token);
                onLogin();
                navigate('/users_app');
            } else {
                setError(data.message || 'Errore di login');
            }
        } catch (err) {
            setError('Errore di connessione');
        }
    };

    return (
        <form onSubmit={handleLogin} style={styles.form}>
            <h2 style={styles.header}>Login</h2>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                style={styles.input}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={styles.input}
            />
            <button type="submit" style={styles.button}>Accedi</button>
            {error && <p style={styles.error}>{error}</p>}
        </form>
    );
}

const styles = {
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        margin: 'auto',
        padding: '2rem',
        backgroundColor: '#f7f9fc',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        marginBottom: '1rem',
        fontSize: '24px',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '1rem',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontSize: '16px',
    },
    button: {
        padding: '10px 15px',
        color: '#fff',
        backgroundColor: '#3498db',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    error: {
        color: '#e74c3c',
        marginTop: '1rem',
        fontSize: '14px',
    }
};

export default LoginComponent;
