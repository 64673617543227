import React, { useEffect, useState } from 'react';

function UserManagementComponent() {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');  // Recupera il token dal localStorage
            console.log("Token per la richiesta:", token); // Debug per vedere il token
    
            const response = await fetch('/api/users_app', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.ok) {
                setUsers(data);
            } else {
                setError(data.message || 'Errore nel caricamento degli utenti');
            }
        } catch (err) {
            setError('Errore di connessione');
        }
    };
    
    const fetchRoles = async () => {
        try {
            const response = await fetch('/api/roles_app');
            const data = await response.json();
            console.log("Roles data:", data);  // Aggiungi questo per fare il debug
            if (response.ok) {
                setRoles(data);
            }
        } catch (err) {
            setError('Errore nel caricamento dei ruoli');
            console.error(err);  // Aggiungi il log dell'errore
        }
    };

    const updateRole = async (userId, roleId) => {
        try {
            const response = await fetch(`/api/users_app/${userId}/roles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ roleId })  // Assicurati che l'oggetto sia { roleId: valore }
            });
            if (response.ok) {
                fetchUsers();  // Aggiorna la lista utenti dopo la modifica
            } else {
                const data = await response.json();
                setError(data.message || 'Errore nella modifica del ruolo');
            }
        } catch (err) {
            setError('Errore di connessione');
            console.error(err);
        }
    };
    
    const removeRole = async (userId, roleId) => {
        try {
            const response = await fetch(`/api/users_app/${userId}/roles`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ roleId })
            });
            if (response.ok) {
                fetchUsers();  // Aggiorna la lista degli utenti dopo la modifica
            } else {
                const data = await response.json();
                setError(data.message || 'Errore nella rimozione del ruolo');
            }
        } catch (err) {
            setError('Errore di connessione');
            console.error(err);  // Log dell'errore
        }
    };
    
    return (
        <div style={styles.container}>
            <h2 style={styles.header}>Gestione Utenti</h2>
            {error && <p style={styles.error}>{error}</p>}
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.th}>Username</th>
                        <th style={styles.th}>Email</th>
                        <th style={styles.th}>Ruolo</th>
                        <th style={styles.th}>Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.userid}>
                            <td style={styles.td}>{user.username}</td>
                            <td style={styles.td}>{user.email}</td>
                            <td style={styles.td}>
                                {user.roles.map((role) => (
                                    <div key={role.roleId} style={styles.roleContainer}>
                                        <span>{role.roleName}</span>
                                        <button 
                                            onClick={() => removeRole(user.userid, role.roleId)} 
                                            style={styles.removeButton}
                                        >
                                            Rimuovi
                                        </button>
                                    </div>
                                ))}
                            </td>
                            <td style={styles.td}>
                                <select
                                    onChange={(e) => updateRole(user.userid, parseInt(e.target.value))}
                                    defaultValue={user.roles[0]?.roleId || ''}
                                    style={styles.select}
                                >
                                    <option value="">Seleziona ruolo</option>
                                    {roles.map((role) => (
                                        <option key={role.roleId} value={role.roleId}>
                                            {role.roleName}
                                        </option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f7f9fc',
        padding: '20px',
    },
    header: {
        color: '#333',
        fontSize: '24px',
        marginBottom: '20px',
    },
    error: {
        color: '#e74c3c',
        marginBottom: '15px',
    },
    table: {
        width: '90%',
        maxWidth: '800px',
        borderCollapse: 'collapse',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        borderRadius: '8px',
    },
    th: {
        backgroundColor: '#3498db',
        color: '#fff',
        padding: '12px',
        fontSize: '16px',
    },
    td: {
        padding: '10px',
        borderBottom: '1px solid #eaeaea',
        fontSize: '14px',
        textAlign: 'center',
    },
    roleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    removeButton: {
        marginLeft: '10px',
        padding: '5px 8px',
        fontSize: '12px',
        color: '#e74c3c',
        backgroundColor: 'transparent',
        border: '1px solid #e74c3c',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'all 0.2s',
    },
    removeButtonHover: {
        backgroundColor: '#e74c3c',
        color: '#fff',
    },
    select: {
        padding: '8px',
        fontSize: '14px',
        borderRadius: '4px',
        border: '1px solid #dcdcdc',
        backgroundColor: '#f9f9f9',
        cursor: 'pointer',
    }
};

export default UserManagementComponent;
