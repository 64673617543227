// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trailers-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .trailers-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .trailers-container .file-upload {
    margin-bottom: 20px;
  }
  
  .trailers-container .trailers-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .trailers-container .trailers-table th, .trailers-container .trailers-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .trailers-container .trailers-table th {
    background-color: #f2f2f2;
  }
  
  .trailers-container .trailers-table td {
    vertical-align: top;
  }
  
  .trailers-container .button {
    margin-right: 10px;
  }
  
  .buttons {
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  `, "",{"version":3,"sources":["webpack://./src/component/Trailers.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;IACf,eAAe;IACf,eAAe;EACjB","sourcesContent":[".trailers-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n  }\n  \n  .trailers-container h1 {\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .trailers-container .file-upload {\n    margin-bottom: 20px;\n  }\n  \n  .trailers-container .trailers-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n  }\n  \n  .trailers-container .trailers-table th, .trailers-container .trailers-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .trailers-container .trailers-table th {\n    background-color: #f2f2f2;\n  }\n  \n  .trailers-container .trailers-table td {\n    vertical-align: top;\n  }\n  \n  .trailers-container .button {\n    margin-right: 10px;\n  }\n  \n  .buttons {\n    border: none;\n    color: white;\n    padding: 10px 20px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 4px 2px;\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
