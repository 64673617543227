// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* VehicleDetail.css */
.container_card {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 20px;
}

.card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    background-color: #ffffff;
}

.card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-header h2 {
    margin: 0;
    font-size: 1.5em;
    color: #333333;
}

.card-body {
    padding: 20px;
}

.vehicle-details {
    list-style: none;
    padding: 0;
    margin: 0;
}

.vehicle-details li {
    padding: 10px 0;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
}

.vehicle-details li strong {
    width: 200px;
    color: #555555;
}

.vehicle-details li:last-child {
    border-bottom: none;
}

.spinner, .error {
    text-align: center;
    font-size: 1.2em;
    padding: 50px 0;
    color: #666666;
}

.error {
    color: #ff4d4d;
}
`, "",{"version":3,"sources":["webpack://./src/component/VehicleDetail.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;IACxC,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,gCAAgC;IAChC,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;AACb;;AAEA;IACI,eAAe;IACf,gCAAgC;IAChC,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* VehicleDetail.css */\n.container_card {\n    max-width: 900px;\n    margin: 0 auto;\n    margin-top: 50px;\n    padding: 20px;\n}\n\n.card {\n    border: 1px solid #e0e0e0;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    margin-bottom: 20px;\n    background-color: #ffffff;\n}\n\n.card-header {\n    background-color: #f8f9fa;\n    border-bottom: 1px solid #e0e0e0;\n    padding: 20px;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.card-header h2 {\n    margin: 0;\n    font-size: 1.5em;\n    color: #333333;\n}\n\n.card-body {\n    padding: 20px;\n}\n\n.vehicle-details {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n}\n\n.vehicle-details li {\n    padding: 10px 0;\n    border-bottom: 1px solid #f0f0f0;\n    display: flex;\n    justify-content: space-between;\n}\n\n.vehicle-details li strong {\n    width: 200px;\n    color: #555555;\n}\n\n.vehicle-details li:last-child {\n    border-bottom: none;\n}\n\n.spinner, .error {\n    text-align: center;\n    font-size: 1.2em;\n    padding: 50px 0;\n    color: #666666;\n}\n\n.error {\n    color: #ff4d4d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
