import React, { useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Blog from './component/Blog';
import Contact from './component/Contact';
import Home from './component/Home';
import Services from './component/Services';
import Team from './component/Team';
import Login from './component/Login';
import Dashboard from './component/Dashboard';
import PrivateNavbar from './component/PrivateNavbar';
import GestionaleNavbar from './component/GestionaleNavbar';
import Signup from './component/Signup';
import Logout from './component/Logout';
import PrivateRoute from './component/PrivateRoute';
import Unauthorized from './component/Unauthorized'; 
import Navbar from './component/Navbar';
import VehiclesDetail from './component/VehicleDetail';
import Trailers from './component/Trailers';
import Vehicles from './component/Vehicles';
import UpdateVehicle from './component/UpdataVehicle'; // Corretto il nome del file
import Policy from './component/Policy';
import Employees from './component/Employees';
import BestChoice from './component/BestChoice';
import RegisterVehicle from './component/RegisterVehicle';
import Truck3d from './component/Truck3d';
import Vehicle3d from './component/Vehicle3d';
import Footer from './component/Footer'; // Importa il componente Footer
import Gestionale from './component/Gestionale'; 
import UserManagementComponent from './component/app/UserManagerComponent';
import LoginComponent from './component/app/LoginComponent';
import SignupComponent from './component/app/SignupComponent';
import { useEffect } from 'react'; // Importa `useEffect` da React
import { Navigate } from 'react-router-dom'; // Importa `Navigate` da react-router-dom


function App() {
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [role, setRole] = useState(localStorage.getItem('role') || '');

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const saveRole = (userRole) => {
    localStorage.setItem('role', userRole);
    setRole(userRole);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    setToken('');
    setRole('');
  };

  const location = useLocation();
  const isPrivateRoute = location.pathname.startsWith("/dashboard");
  const isGestionaleRoute = location.pathname.startsWith("/gestionale");

  const hideNavbarFooterRoutes = ['/login_app', '/signup_app', '/users_app'];
  const hideNavbarFooter = hideNavbarFooterRoutes.includes(location.pathname);

  

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
      // Verifica se il token di autenticazione è presente al caricamento della pagina
      const token = localStorage.getItem('token');
      setLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
      localStorage.removeItem('token');
      setLoggedIn(false);
  };


  return (
    <div className="App">
      {!hideNavbarFooter && (
                <>
                    {isGestionaleRoute ? (
                        <GestionaleNavbar logout={logout} />
                    ) : isPrivateRoute ? (
                        <PrivateNavbar logout={logout} />
                    ) : (
                        <Navbar />
                    )}
                </>
            )}
      <Routes>
      {/* <Route path="/" element={loggedIn ? <Navigate to="/users_app" /> : <Navigate to="/login_app" />}/> */}
                    <Route
                        path="/login_app"
                        element={<LoginComponent onLogin={() => setLoggedIn(true)} />}
                    />
                    <Route path="/signup_app" element={<SignupComponent />} />
                    <Route
                        path="/users_app"
                        element={loggedIn ? <UserManagementComponent /> : <Navigate to="/login_app" />}
                    />




        <Route path="/blog" element={<Blog />} />
        <Route path="/team" element={<Team />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login setToken={saveToken} setRole={saveRole} />} />
        <Route path="/logout" element={<Logout logout={logout} />} />
        <Route path="/dashboard" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Dashboard logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/vehicles" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Vehicles logout={logout} /></PrivateRoute>} />
        <Route path="/gestionale" element={<PrivateRoute token={token} role={role} requiredRole="gestionale"><Gestionale logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/trailers" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Trailers logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/3dvehicles" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Truck3d logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/vehicles/3d/:id" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Vehicle3d logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/vehicles/:id" element={<PrivateRoute token={token} role={role} requiredRole="admin"><VehiclesDetail logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/vehicles/edit/:id" element={<PrivateRoute token={token} role={role} requiredRole="admin"><UpdateVehicle logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/bestchoice" element={<PrivateRoute token={token} role={role} requiredRole="admin"><BestChoice logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/registervehicle" element={<PrivateRoute token={token} role={role} requiredRole="admin"><RegisterVehicle logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/policy" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Policy logout={logout} /></PrivateRoute>} />
        <Route path="/dashboard/employees" element={<PrivateRoute token={token} role={role} requiredRole="admin"><Employees logout={logout} /></PrivateRoute>} />
        <Route path="/user-dashboard" element={<PrivateRoute token={token} role={role} requiredRole="user"><Dashboard logout={logout} /></PrivateRoute>} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
      {!hideNavbarFooter && <Footer />}
    </div>
  );
}

export default App;
